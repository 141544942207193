import * as React from 'react';
import type { SVGProps } from 'react';

function SvgEditIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 16 16' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.333}
        d='M1.919 12.077c.03-.276.046-.413.087-.542.037-.114.09-.223.156-.324.074-.113.172-.21.368-.407L11.335 2A1.886 1.886 0 1 1 14 4.667L5.197 13.47c-.196.196-.294.294-.407.369-.1.066-.21.118-.323.155-.13.042-.267.057-.543.088l-2.256.25.25-2.256Z'
      />
    </svg>
  );
}
export default SvgEditIcon;
