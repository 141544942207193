import * as React from 'react';
import type { SVGProps } from 'react';

function SvgBankIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 20 20' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.667}
        d='M4.167 7.5v6.667M7.917 7.5v6.667M12.083 7.5v6.667m3.75-6.667v6.667M2.5 15.5v.667c0 .466 0 .7.09.878.08.157.208.284.365.364.178.091.412.091.878.091h12.334c.466 0 .7 0 .878-.09a.833.833 0 0 0 .364-.365c.091-.178.091-.412.091-.878V15.5c0-.467 0-.7-.09-.878a.833.833 0 0 0-.365-.364c-.178-.091-.412-.091-.878-.091H3.833c-.466 0-.7 0-.878.09a.833.833 0 0 0-.364.365c-.091.178-.091.411-.091.878ZM9.71 2.564l-6.166 1.37c-.372.083-.559.125-.698.225a.833.833 0 0 0-.278.347c-.068.158-.068.349-.068.73v.93c0 .467 0 .7.09.879.08.157.208.284.365.364.178.091.412.091.878.091h12.334c.466 0 .7 0 .878-.09a.833.833 0 0 0 .364-.365c.091-.178.091-.412.091-.878v-.93c0-.382 0-.573-.068-.73a.834.834 0 0 0-.278-.348c-.14-.1-.326-.142-.698-.224l-6.167-1.37a1.735 1.735 0 0 0-.216-.041.833.833 0 0 0-.146 0 1.576 1.576 0 0 0-.216.04Z'
      />
    </svg>
  );
}
export default SvgBankIcon;
