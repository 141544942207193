import * as React from 'react';
import type { SVGProps } from 'react';

function SvgSearchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m21 21-4.35-4.35M19 11a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z'
      />
    </svg>
  );
}
export default SvgSearchIcon;
