import * as React from 'react';
import type { SVGProps } from 'react';

function SvgXIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 20 20' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        d='M15 5 5 15M5 5l10 10'
      />
    </svg>
  );
}
export default SvgXIcon;
