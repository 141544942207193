import * as React from 'react';
import type { SVGProps } from 'react';

function SvgChevronLeftIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 20 20' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.667}
        d='m12.5 15-5-5 5-5'
      />
    </svg>
  );
}
export default SvgChevronLeftIcon;
