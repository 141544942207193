import * as React from 'react';
import type { SVGProps } from 'react';

function SvgFiltersIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 20 20' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        d='M12.542 7.5H4.583a2.083 2.083 0 0 1 0-4.167h7.96M7.457 16.667h7.959a2.083 2.083 0 0 0 0-4.167h-7.96M2.5 14.583a2.917 2.917 0 1 0 5.833 0 2.917 2.917 0 0 0-5.833 0Zm15-9.166a2.917 2.917 0 1 1-5.833 0 2.917 2.917 0 0 1 5.833 0Z'
      />
    </svg>
  );
}
export default SvgFiltersIcon;
