import * as React from 'react';
import type { SVGProps } from 'react';

function SvgCheckDoneIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 25 25' {...props}>
      <path
        fill='currentColor'
        d='M2.5 11.45c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C4.02 8.25 4.58 8.25 5.7 8.25h7.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v7.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.428.218-.988.218-2.108.218H5.7c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2.5 20.73 2.5 20.17 2.5 19.05v-7.6Z'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m6.5 15.25 2 2 4.5-4.5m-4.5-4.5v-2.8c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h7.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v7.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.428.218-.988.218-2.108.218h-2.8m-10.8 6h7.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874c.218-.428.218-.988.218-2.108v-7.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874c-.428-.218-.988-.218-2.108-.218H5.7c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2.5 9.77 2.5 10.33 2.5 11.45v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218Z'
      />
    </svg>
  );
}
export default SvgCheckDoneIcon;
