import * as React from 'react';
import type { SVGProps } from 'react';

function SvgAnnotationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.28516 8.25557C3.28516 6.57541 3.28516 5.73533 3.61214 5.09359C3.89976 4.52911 4.3587 4.07017 4.92318 3.78255C5.56492 3.45557 6.405 3.45557 8.08516 3.45557H16.4852C18.1653 3.45557 19.0054 3.45557 19.6471 3.78255C20.2116 4.07017 20.6706 4.52911 20.9582 5.09359C21.2852 5.73533 21.2852 6.57541 21.2852 8.25557V13.9556C21.2852 15.3534 21.2852 16.0523 21.0568 16.6036C20.7523 17.3387 20.1683 17.9227 19.4332 18.2272C18.8819 18.4556 18.183 18.4556 16.7852 18.4556C16.2966 18.4556 16.0523 18.4556 15.8257 18.5091C15.5235 18.5804 15.242 18.7211 15.0037 18.9201C14.8249 19.0693 14.6783 19.2647 14.3852 19.6556L12.9252 21.6022C12.708 21.8917 12.5995 22.0365 12.4664 22.0882C12.3498 22.1336 12.2205 22.1336 12.1039 22.0882C11.9708 22.0365 11.8623 21.8917 11.6452 21.6022L10.1852 19.6556C9.89201 19.2647 9.74544 19.0693 9.56665 18.9201C9.32827 18.7211 9.04685 18.5804 8.74466 18.5091C8.51802 18.4556 8.27373 18.4556 7.78516 18.4556C6.38733 18.4556 5.68842 18.4556 5.13711 18.2272C4.40202 17.9227 3.818 17.3387 3.51352 16.6036C3.28516 16.0523 3.28516 15.3534 3.28516 13.9556V8.25557Z'
        fill='white'
      />
      <path
        d='M10.2852 8.9578C10.4614 8.45693 10.8091 8.03457 11.2669 7.76554C11.7247 7.49651 12.2629 7.39817 12.7862 7.48793C13.3095 7.5777 13.7842 7.84977 14.1261 8.25597C14.468 8.66217 14.6552 9.17628 14.6544 9.70724C14.6544 11.2061 12.4061 11.9556 12.4061 11.9556'
        fill='white'
      />
      <path
        d='M10.2852 8.9578C10.4614 8.45693 10.8091 8.03457 11.2669 7.76554C11.7247 7.49651 12.2629 7.39817 12.7862 7.48793C13.3095 7.5777 13.7842 7.84977 14.1261 8.25597C14.468 8.66217 14.6552 9.17628 14.6544 9.70724C14.6544 11.2061 12.4061 11.9556 12.4061 11.9556M12.4351 14.9556H12.4451M10.1852 19.6556L11.6452 21.6022C11.8623 21.8917 11.9708 22.0365 12.1039 22.0882C12.2205 22.1336 12.3498 22.1336 12.4664 22.0882C12.5995 22.0365 12.708 21.8917 12.9252 21.6022L14.3852 19.6556C14.6783 19.2647 14.8249 19.0693 15.0037 18.9201C15.242 18.7211 15.5235 18.5804 15.8257 18.5091C16.0523 18.4556 16.2966 18.4556 16.7852 18.4556C18.183 18.4556 18.8819 18.4556 19.4332 18.2272C20.1683 17.9227 20.7523 17.3387 21.0568 16.6036C21.2852 16.0523 21.2852 15.3534 21.2852 13.9556V8.25557C21.2852 6.57541 21.2852 5.73533 20.9582 5.09359C20.6706 4.52911 20.2116 4.07017 19.6471 3.78255C19.0054 3.45557 18.1653 3.45557 16.4852 3.45557H8.08516C6.405 3.45557 5.56492 3.45557 4.92318 3.78255C4.3587 4.07017 3.89976 4.52911 3.61214 5.09359C3.28516 5.73533 3.28516 6.57541 3.28516 8.25557V13.9556C3.28516 15.3534 3.28516 16.0523 3.51352 16.6036C3.818 17.3387 4.40202 17.9227 5.13711 18.2272C5.68842 18.4556 6.38733 18.4556 7.78516 18.4556C8.27373 18.4556 8.51802 18.4556 8.74466 18.5091C9.04685 18.5804 9.32827 18.7211 9.56665 18.9201C9.74544 19.0693 9.89201 19.2647 10.1852 19.6556Z'
        stroke='#238651'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
export default SvgAnnotationIcon;
