import * as React from 'react';
import type { SVGProps } from 'react';

function SvgSaveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 17 16' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.333}
        d='M5.167 2v2.267c0 .373 0 .56.072.702a.667.667 0 0 0 .292.292c.142.072.329.072.702.072h4.534c.373 0 .56 0 .702-.072a.666.666 0 0 0 .292-.292c.072-.142.072-.329.072-.702v-1.6m0 11.333V9.733c0-.373 0-.56-.072-.702a.666.666 0 0 0-.292-.292c-.142-.072-.329-.072-.702-.072H6.233c-.373 0-.56 0-.702.072a.667.667 0 0 0-.292.292c-.072.142-.072.329-.072.702V14M14.5 6.217V10.8c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C12.98 14 12.42 14 11.3 14H5.7c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2.5 12.48 2.5 11.92 2.5 10.8V5.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C4.02 2 4.58 2 5.7 2h4.583c.326 0 .49 0 .643.037.136.033.266.086.385.16.135.082.25.197.48.428l2.084 2.083c.23.231.346.346.428.481.074.12.127.25.16.385.037.154.037.317.037.643Z'
      />
    </svg>
  );
}
export default SvgSaveIcon;
