import * as React from 'react';
import type { SVGProps } from 'react';

function SvgArrowRightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M5 12h14m0 0-7-7m7 7-7 7'
      />
    </svg>
  );
}
export default SvgArrowRightIcon;
