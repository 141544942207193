import * as React from 'react';
import type { SVGProps } from 'react';

function SvgMailIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 20 20' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.667}
        d='m17.917 15-5.536-5M7.62 10l-5.535 5m-.417-9.167 6.804 4.763c.551.386.827.579 1.126.653.265.066.542.066.806 0 .3-.074.576-.267 1.127-.653l6.804-4.763M5.667 16.667h8.667c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.092-1.092c.273-.535.273-1.235.273-2.635V7.333c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092c-.535-.273-1.235-.273-2.635-.273H5.667c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092c-.272.535-.272 1.235-.272 2.635v5.334c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092c.535.273 1.235.273 2.635.273Z'
      />
    </svg>
  );
}
export default SvgMailIcon;
