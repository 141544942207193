import * as React from 'react';
import type { SVGProps } from 'react';

function SvgAlertTriangleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 17 16' {...props}>
      <g clipPath='url(#a)'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.333}
          d='M8.5 6v2.667m0 2.666h.007m-.93-8.739-5.483 9.472c-.305.525-.457.787-.434 1.003a.67.67 0 0 0 .27.47c.176.128.48.128 1.087.128h10.966c.607 0 .91 0 1.086-.128a.667.667 0 0 0 .271-.47c.023-.216-.13-.478-.434-1.004l-5.483-9.47c-.303-.524-.454-.786-.652-.874a.667.667 0 0 0-.542 0c-.198.088-.35.35-.652.873Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='currentColor' d='M.5 0h16v16H.5z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgAlertTriangleIcon;
