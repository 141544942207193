import * as React from 'react';
import type { SVGProps } from 'react';

function SvgTrashIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 16 17' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.333}
        d='M6 2.5h4m-8 2h12m-1.333 0-.468 7.013c-.07 1.052-.105 1.578-.332 1.977a2 2 0 0 1-.866.81c-.413.2-.94.2-1.995.2H6.994c-1.055 0-1.582 0-1.995-.2a2 2 0 0 1-.866-.81c-.227-.399-.262-.925-.332-1.977L3.333 4.5m3.334 3v3.333M9.333 7.5v3.333'
      />
    </svg>
  );
}
export default SvgTrashIcon;
