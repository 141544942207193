import * as React from 'react';
import type { SVGProps } from 'react';

function SvgCheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 16 17' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        d='M13.333 4.5 6 11.833 2.667 8.5'
      />
    </svg>
  );
}
export default SvgCheckIcon;
