import * as React from 'react';
import type { SVGProps } from 'react';

function SvgEdit04Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m21 18-1 1.094c-.53.58-1.25.906-2 .906a2.71 2.71 0 0 1-2-.906 2.716 2.716 0 0 0-2-.904c-.75 0-1.469.325-2 .904M3 20h1.675c.489 0 .733 0 .963-.055.205-.05.4-.13.579-.24.201-.123.374-.296.72-.642L19.5 6.5a2.121 2.121 0 1 0-3-3L3.937 16.063c-.346.346-.519.519-.642.72a2 2 0 0 0-.24.578c-.055.23-.055.475-.055.965V20Z'
      />
    </svg>
  );
}
export default SvgEdit04Icon;
