import * as React from 'react';
import type { SVGProps } from 'react';

function SvgInfoCircleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 16 16' {...props}>
      <g clipPath='url(#a)'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M8 10.667V8m0-2.667h.007M14.667 8A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.334 0Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='currentColor' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgInfoCircleIcon;
